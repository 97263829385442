<template>
    <div v-if="item" class="news">
        <!--Banner v-if="item.title" :src="null" :title="item.title" :clipped='false' /-->   
        <p class="headline h1 text-primary text-uppercase mt-2x">{{item.title}}</p>
        <div class="px-1 py-1" v-if="item.creator">
            Criado por: {{ item.creator.firstName + ' ' + item.creator.lastName }}
            <v-icon color="blue" small>mdi-check-decagram</v-icon></div> 
        <v-container fluid>
            <v-row>
                <v-col cols="12" ><!-- md="9" -->
                    <Banner v-if="item.cartaz" :src="item.cartaz" :clipped="false" height="330px"/>
                    <br/>
                    <div
                        class="news-body py-3 text-justify" v-html="item.body"></div>
                    <p
                        class="h4 py-3">Data Final de Registo {{ item.date }}</p>
                </v-col>
                <!--v-col cols="12" md="3">
                    <Timeline :endDate="item.subscriptionDeadline" :startDate="item.date" />
                </v-col-->
            </v-row>
        </v-container>

        <v-container fluid v-if="isLoggedIn">
            <v-row>
                <!-- Open subscription -->
                <v-col cols="4" v-if="!isMyFestival && !item.registerDateExpired && !item.maxSubsReached">
                    <v-btn small color="primary" class="black--text elevation-0" @click="dialog = true">
                        <span class="black--text">Concorrer</span></v-btn>
                    <v-btn
                        small
                        color="primary"
                        class="black--text elevation-0 ml-2"
                        @click="favorites(item.addedToUserFavorite ? 'REMOVE' : 'ADD')">
                        <v-icon :color="item.addedToUserFavorite ? 'black': 'white'">mdi-star</v-icon>
                    </v-btn>

                    <AddToFavorite :item="item" code='NOT_INTERESTED' />

                    <v-snackbar
                        v-model="snackbar"
                        v-if="error"
                        bottom
                        :color="error.success ? 'success' : 'error'"
                        outlined
                    >
                        {{ error.error }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                                color="pink"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-col>

                <v-alert
                    type="error"
                    v-if="isMyFestival"
                    border="bottom"
                    colored-border
                >
                    Não pode subscrever ao seu proprio festival.
                </v-alert>

                <v-alert
                    v-if="item.registerDateExpired"
                    border="bottom"
                    colored-border
                    type="warning"
                >
                    <span>Data Subscrição Expirada</span>
                </v-alert>

                <v-alert
                    type="error"
                    v-if="item.maxSubsReached"
                    border="bottom"
                    colored-border
                >
                    Limite máximo({{ item.maxSubsAllowed }}) de subsrição atiginda. Já não podes subscrever.
                </v-alert>

                <!-- Comment Section -->
                <!--v-col cols="12">
                   <CommentSection />
                </v-col-->
                
            </v-row>
        </v-container>

        <v-container v-else>
            <v-row>
                <v-col :cols="!item.registerDateExpired ?  '4' : '8'" v-if="!isMyFestival">
                    <v-btn
                        color="primary"
                        class="black--text elevation-0"
                        @click="loginDialog = true">
                        <span class="black--text">Login para concorrer</span>
                    </v-btn>
                </v-col>
            </v-row>
            
            <LoginDialog 
                :dialog="loginDialog" 
                @close="loginDialog = false" 
                @success="onSuccess"/>
        </v-container>

        <v-container fluid>
            <v-row>
                <v-col></v-col>
                <v-col cols="9">
                    <v-row align="center" justify="center">
                        <v-col cols="3" v-for="(award, i) in item.awards" :key="i">
                            <img :src="award.src" height="100px" width="200px" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-container>

        <SubscribeDialog
            v-if="item.allowedProjects && item.allowedProjects.length > 0 && !item.registerDateExpired"
            :dialog="dialog"
            :allowedProjects="item.allowedProjects"
            v-on:changeDialog="val => dialog = val"
            :allowedCategories="allowedCategories"
        />
    </div>
</template>
<script>
import Banner from '../Banner';
//import Timeline from '../Timeline';
import SubscribeDialog from '../Subscribe';
import LoginDialog from '../LoginDialog';
import AddToFavorite from './AddToFavorite';
// import CommentSection from '../CommentSection';

import { mapState, mapActions } from 'vuex';

//import { SESSION_ID } from '../../utils/index';

export default {
    data() {
        return {
            dialog: false,
            loginDialog: false,
            snackbar: false,
            errr: null
        }
    },
    props: ['item',],
    
    components: {
        Banner,
        //Timeline,
        SubscribeDialog,
        LoginDialog,
        AddToFavorite
        //CommentSection
    },

    computed: {
        isLoggedIn() {
            //return this.$session.get(SESSION_ID) ? true : false;
            if (this.$user.isGuest) {
                return false;
            }
            return true;
        },

        owner() {
             if (this.$user.isGuest) {
                return null;
            }
            return this.$user.info.id;
            //return this.$session.get(SESSION_ID) ? this.$session.get(SESSION_ID).id : null;
        },

        allowedCategories() {
            if (this.item.allowedProjects)
                return this.item.allowedProjects.map(o => o.id)
            return []
        },
        festival() {
            return this.$route.params.id || null;
        },
        ...mapState('festival/festival', ['isMyFestival', 'error']),
    },

    created() {
        if (this.owner && this.allowedCategories.length > 0 && this.festival) {
            const payload = {
                author: this.owner,
                categories: this.allowedCategories,
                festival_: this.festival
            };
            this.fetchProjects(payload);
        }
    },

    methods: {
        ...mapActions('project/project', {
            fetchProjects: 'projectsToSubscribe'
        }),
        ...mapActions('festival/festival', {
            manageFavorites: 'manageFavorites'
        }),
        onSuccess() {
            this.loginDialog = false;
            this.refreshUser();
        },
        refreshUser() {
            if (this.$user.isAuthenticated) {
                return this.user = this.$user.info;
            }
            this.user = null;
        },

        async favorites(action) {
            const payload = {
                profile: this.owner,
                festival: this.festival,
                action: action
            };
            await this.manageFavorites(payload);
            this.snackbar = true;
            if (this.error.success && action == 'ADD')
                this.item.addedToUserFavorite = true;
            else if (this.error.success && action == 'REMOVED')
                this.item.addedToUserFavorite = false;
        }
    },

    watch: {
        isLoggedIn() {
            this.refreshUser();
        },
        dialog(val) {
            if (val)
                this.fetchProjects({ author: this.owner, categories: this.allowedCategories, festival_: this.festival });
        }
    }
}
</script>