<template>
  <v-row justify="center">
    <v-dialog
      v-model="thisDialog"
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline" v-if="projectsToSubscribe.length > 0">
          Projetos que podem concorrer
        </v-card-title>
        <v-alert v-else type="error" outlined dense>
          Lamentamos, mas não tem nenhum projeto que pode ser subscrito a este tipo de festival.
        </v-alert>
        <v-card-text>
          <div v-for="allowedProject in allowedProjects" :key="allowedProject.id">
              <span>{{ allowedProject.name }}</span>
          </div>

          <v-alert class="black--text" :type="status.success ? 'primary' :'error'" v-if="status">
            {{ status.error }}
          </v-alert>
          <div>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="projectsToSubscribe"
                :single-select="singleSelect"
                item-key="id"
                show-select
                class="elevation-0"
            >
                <template v-slot:top>
                    <v-switch
                      v-model="singleSelect"
                      label="Selecionar Todos"
                      class="pa-3"></v-switch>
                </template>
            </v-data-table>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            class="elevation-0"
            small
            :disabled="projectsToSubscribe.length <= 0"
            @click="subscribe"
          >
            <span class="black--text">Subscrever</span>
          </v-btn>

          <v-btn
            color="red"
            class="elevation-0"
            small
            @click="thisDialog = false"
          >
            <span class="black--text">Cancelar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapState, mapActions } from 'vuex';

import { CONTROL_PANEL } from '../routes';

export default {
    props: ['dialog', 'allowedProjects', 'allowedCategories'],
    data() {
        return {
            selected: [],
            singleSelect: false,
            headers: [
                {
                    text: 'Projeto',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Categoria', value: 'category' },
            ],
        }
    },

    methods: {
        subscribe() {
            let projects = this.selected.map(p => p.id);
            this.subscribeFestival({ festival: this.$route.params.id, projects: projects });
            if (this.status.success) {
              this.$router.push({ path: CONTROL_PANEL.dashboard.subscriptions });
            }
        },

        ...mapActions('project/project', {
            subscribeFestival: 'subscribeFestival'
        })
    },

    computed: {
        thisDialog: {
            get() {
                return this.dialog
            },
            set(val) {
                this.$emit('changeDialog', val);
            }
        },

        ...mapState('project/project', ['projectsToSubscribe', 'status'])
    },
}
</script>