<template>
    <div>
        <FestivalView :item="festival" />
    </div>
</template>

<script>
import FestivalView from '../components/festival/View';


import { mapActions, mapState } from 'vuex';
//import { SESSION_ID } from '../utils/index'

export default {
    data: () => ({}),
    created () {
        //this.find({ id: this.$route.params.id, profile: this.$session.get(SESSION_ID).id });
        /*if (this.$session.get(SESSION_ID)) {
            this.isMyFestival({ festival: this.$route.params.id,  profile: this.$session.get(SESSION_ID).id })
        }*/
        this.find({ id: this.$route.params.id, profile: this.owner });
        if (this.owner) {
            this.isMyFestival({ festival: this.$route.params.id,  profile: this.owner })
        }
    },
    computed: { 
        ...mapState('festival/festival', ['festival', 'loading']),
        owner() {
             if (this.$user.isGuest) {
                return null;
            }
            return this.$user.info.id;
            //return this.$session.get(SESSION_ID) ? this.$session.get(SESSION_ID).id : null;
        },
    },
    methods: { 
        ...mapActions('festival/festival', {
            find: 'detail',
            isMyFestival: 'isMyFestival'
        }),
    },
    components: {
        FestivalView,
    },
}
</script>