<template>
<span>
    <v-btn :title="addedToUserFavorite ? 'Remover' : 'Adicionar' "
        small
        color="primary"
        class="black--text elevation-0 ml-2"
        @click="favorites(addedToUserFavorite ? 'REMOVE' : 'ADD')">
        <v-icon 
            :color="addedToUserFavorite ? 'black': 'white'">
            {{ code === 'IN_VIEW' ? 'mdi-star' : 'mdi-eye-off' }}
        </v-icon>
    </v-btn>
    <v-snackbar
        v-model="snackbar"
        v-if="error"
        bottom
        :color="error.success ? 'success' : 'error'"
        outlined
    >
        {{ error.error }}

        <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</span>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
//const IN_VIEW = 'IN_VIEW';
//const NOT_INTERESTED = 'NOT_INTERESTED';
export default {
    props:['item', 'code'],
    data() {
        return {
            snackbar: false,
            errr: {},
            festivalItem: {
                addedToUserFavorite: false
            }
        }
    },
    created() {
        this.festivalItem = _.cloneDeep(this.item);
    },
    computed: {
        ...mapState('festival/festival', ['isMyFestival', 'error']),
        isLoggedIn() {
            //return this.$session.get(SESSION_ID) ? true : false;
            if (this.$user.isGuest) {
                return false;
            }
            return true;
        },
        addedToUserFavorite() {
            let added = false;

            if (this.code === 'IN_VIEW') {
                added = this.festivalItem.addedToUserFavorite;
            } else {
                //added = festivalItem.????addedToNotInterested????
            }
            return added;
        },
        owner() {
             if (this.$user.isGuest) {
                return null;
            }
            return this.$user.info.id;
            //return this.$session.get(SESSION_ID) ? this.$session.get(SESSION_ID).id : null;
        },

        allowedCategories() {
            if (this.festivalItem.allowedProjects)
                return this.festivalItem.allowedProjects.map(o => o.id)
            return []
        },
        festival() {
            return this.festivalItem.id || null;
        },
        ...mapState('festival/festival', ['isMyFestival', 'error']),
    },
    methods:{

        ...mapActions('festival/festival', {
            manageFavorites: 'manageFavorites'
        }),

        async favorites(action) {
            const payload = {
                profile: this.owner,
                festival: this.festival,
                action: action,
                code: this.code
            };
            await this.manageFavorites(payload);
            this.snackbar = true;
            if (this.error.success && action == 'ADD')
                this.festivalItem.addedToUserFavorite = true;
            else if (this.error.success && action == 'REMOVED')
                this.festivalItem.addedToUserFavorite = false;
        }
    }
}
</script>